import classNames from "classnames";
import { useFormatter, useTranslations } from "next-intl";

import CrossCountry from "~/types/cross-country";
import intlUtils from "~/utils/intl-utils";
import productUtils from "~/utils/product-utils";

import styles from "./product-price.module.scss";

type Props = {
  product: CrossCountry.Product;
  customStyles?: {
    container?: string;
    discount?: string;
    displayPrice?: string;
    originalPrice?: string;
  };
};

export default function ProductPrice(props: Props) {
  const t = useTranslations();
  const formatter = useFormatter();
  const priceFormatter = intlUtils.usePriceFormatter();
  const { display_price, original_price, currency_id: currency } = props.product;

  // if (!display_price || !currency) {
  //   return <></>;
  // }

  return (
    <div className={classNames(styles.container, props.customStyles?.container)}>
      {original_price ? (
        <p className={classNames(styles.discount, props.customStyles?.discount)}>
          {formatter.number(display_price / original_price - 1, { style: "percent", maximumFractionDigits: 0 })}
        </p>
      ) : null}
      <p className={classNames(styles.displayPrice, props.customStyles?.displayPrice)}>
        {productUtils.isGiftCard(props.product)
          ? t("generic.giftcard.chooseamount")
          : priceFormatter.currency(display_price, { currency })}
      </p>
      {original_price ? (
        <del className={classNames(styles.originalPrice, props.customStyles?.originalPrice)}>
          {priceFormatter.currency(original_price, { currency })}
        </del>
      ) : null}
    </div>
  );
}
