import classNames from "classnames";
import { useTranslations } from "next-intl";

import CrossCountry from "~/types/cross-country";
import CommonUtils from "~/utils/common-utils";

import Icon from "../common/icon";
import styles from "./product-listing-tags.module.scss";

type Props = {
  product: CrossCountry.Product | CrossCountry.ChildrenProduct;
  className?: string;
  keepContainer?: boolean;
};

export default function ProductListingTags(props: Props) {
  const t = useTranslations();
  const hasDiscounts = props.product?.discounts && props.product.discounts.length > 0;
  const hasCutPrice =
    CommonUtils.parseBoolean(process.env.NEXT_PUBLIC_ENABLE_SPECIAL_PROMO_LABEL) &&
    props.product.original_price !== undefined;

  if (!hasDiscounts && !hasCutPrice) {
    return props.keepContainer ? <div className={classNames(styles.tags, props.className)} /> : null;
  }

  return (
    <div className={classNames(styles.tags, props.className)}>
      {hasDiscounts && (
        <div className={styles.tag}>
          <p className={styles.label}>{props.product.discounts[0].PromotionLabel}</p>
        </div>
      )}
      {hasCutPrice && (
        <div className={classNames(styles.tag, hasDiscounts ? styles.percentageTag : undefined)}>
          {hasDiscounts ? <Icon name="percentage" /> : <p className={styles.label}>{t("generic.specialPromo")}</p>}
        </div>
      )}
    </div>
  );
}
